<template>
  <v-container fluid class="pa-0 register">
    <back-to-store :title="$t('profile.title')"></back-to-store>
    <v-container fluid class="pa-16">
      <v-form>
        <v-row class="px-3 py-5 justify-center justify-lg-start">
          <p class="text-h5 black-grin">{{$t('profile.personalInformation')}}</p>
        </v-row>

        <v-row class="flex-column-reverse flex-lg-row">
          <v-col cols="12" lg="8">
            <v-row>
              <v-col cols="12" lg="6">
            <p class="p josefin-light ">{{$t('profile.firstName')}}</p>
            <v-text-field outlined v-model="user.first_name"></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
            <p class="p josefin-light ">{{$t('profile.lastName')}}</p>
            <v-text-field outlined v-model="user.last_name"></v-text-field>
              </v-col>
            </v-row>
            <p class="p josefin-light">{{$t('profile.email')}}</p>
            <v-text-field outlined v-model="user.email" ></v-text-field>
          </v-col>
          <v-col cols="1" class="d-none d-lg-flex">
            <v-img src="@/assets/Linea.png" contain class="line-account"></v-img>
          </v-col>
          <v-col cols="12" lg="3" class="pt-10 pb-10 pb-lg-0">
            <v-row>
              <p class="p josefin-light">{{user.name}}</p>
            </v-row>
            <v-row>
              <p class="copperplate">{{$t('profile.navigationList')}}</p>
            </v-row>
            <v-row>
              <hr class="hr-sub-menu " />
            </v-row>
            <v-row class="pt-5 hr-gold">
              <router-link to="/shipment-info" class="go-to josefin-light p py-2">
                {{$t('profile.shippingInformation')}}
                <v-img src="/icon/one-arrow.svg" width="10" class="ml-auto" />
              </router-link>
              <router-link to="/orders" class="go-to josefin-light p py-2">
                {{$t('profile.purchaseHistory')}}
                <v-img src="/icon/one-arrow.svg" width="10" class="ml-auto" />
              </router-link>
              <router-link to="/productos" class="go-to josefin-light p py-2 mb-5">
                {{$t('profile.logOut')}}
                <v-img src="/icon/one-arrow.svg" width="10" class="ml-auto" />
              </router-link>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="px-3 pt-5 justify-center justify-lg-start">
          <p class="text-h5 black-grin">{{$t('profile.password')}}</p>
        </v-row>
        <v-row class="px-3 mt-n3 pb-5 justify-center justify-lg-start">
          <p class="p josefin-light text-center">{{$t('profile.passwordText')}}</p>
        </v-row>
        <v-row class="px-3">
          <v-col cols="12" lg="6" class="">
            <v-row>
              <p class="p josefin-light">{{$t('profile.oldPassword')}}</p>
            </v-row>
            <v-row>
              <v-text-field outlined v-model="user.oldpassword" ></v-text-field>
            </v-row>
             </v-col>
              <v-col cols="12" lg="6" class="px-lg-10">
             <v-row class="mt-8 mb-5 ">
              <p class="p josefin-light">
                {{$t('profile.messageText')}}
              </p>
            </v-row>

          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" lg="6">
           <v-row>
              <p class="p josefin-light">{{$t('profile.newPassword')}}</p>
            </v-row>
            <v-row>
              <v-text-field outlined v-model="user.password"></v-text-field>
            </v-row>
            </v-col>
             <v-col cols="12" lg="6" class="pl-lg-10 ">
            <v-row>
              <p class="p josefin-light">{{$t('profile.verifiedNewPassword')}}</p>
            </v-row>
            <v-row>
              <v-text-field outlined v-model="user.password_confirmation"></v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="py-5" justify="center">
          <v-btn class="btn_second px-lg-16" type="button" @click="updateUser(user)">
            {{$t('profile.btnUpdate')}}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import backToStore from "@/components/home/back-to-store.vue";

export default {
  components: {
    backToStore
  },
  data: () => ({
    user: {
      email: null,
      first_name: null,
      last_name: null,
      password: null,
      oldpassword: null,
      password_confirmation: null,
      gender: "otro",
      date_of_birth: null
    }
  }),
  computed: {
    ...mapGetters('customer', ['getUser']),
    ...mapGetters(['birthdaytring'])
  },
  methods: {
    ...mapActions('customer', ['updateUser'])
  },
  watch: {
    getUser(val) {
      if (val) {
        this.user = val;
        this.user.date_of_birth = this.birthdaytring;
      }
    }
  },
  async created() {
    this.user = this.getUser;
  }
};
</script>
